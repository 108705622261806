import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Anfahrt } from './Pages/Anfahrt';
import { BasePage } from './Pages/BasePage';
import { Home } from './Pages/Home';
import { Kontakt } from './Pages/Kontakt';
import { Menu } from './Pages/Menu';
import { PhotosPage } from './Pages/PhotosHolder';
import { RSVP } from './Pages/RSVP';
import { Schedule } from './Pages/Schedule';

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/menu', element: <Menu /> },
  { path: '/anfahrt', element: <Anfahrt /> },
  { path: '/schedule', element: <Schedule /> },
  { path: '/contact', element: <Kontakt /> },
  { path: '/photos', element: <PhotosPage /> },
  { path: '/rsvp', element: <RSVP /> },
]);

function App() {
  return (
    <div id="page">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
