import './Anfahrt.css';

import React from 'react';

import { Header } from '../Components/Header';
import { getLanguage } from '../languages/getLanguage';

export const Anfahrt = () => {
  const { NavigationStatic, AnfahrtStatic } = getLanguage();

  return (
    <div className="inner">
      <Header
        image="/img/bmw-1045051_1920.jpg"
        title={NavigationStatic.anfahrt}
      />
      <div className="home" id="anfahrt">
        <div className="row">
          <div className="col-md-9 col-sm-12 anfahrtinfo">
            <span className="t1">
              {AnfahrtStatic.headline}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://maps.app.goo.gl/RovMQs2sy2eQzDwW7"
              >
                Hotel Hartl&apos;s Lindenmühle
              </a>
              .
            </span>
            <span className="t2">
              {AnfahrtStatic.parking1}
              <br />
              {AnfahrtStatic.parking2}
            </span>
            <span className="t3">
              {AnfahrtStatic.parking3}
              <br />
              {AnfahrtStatic.parking4}
            </span>
            <div
              className="background"
              style={{
                backgroundImage:
                  'url(/img/047702d6-1e8b-41e4-b451-e253533f3af2.jpg)',
              }}
            ></div>
          </div>
          <div className="col-sm-6 offset-sm-3 col-md-3 offset-md-0">
            <img src="/img/anfahrt.png" alt="" />
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.919530234966!2d11.6740536768106!3d50.05033871609152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1a0d86bc7a605%3A0xceb4d087e2241351!2sHotel%20Hartl&#39;s%20Lindenm%C3%BChle!5e0!3m2!1sde!2sde!4v1708270383357!5m2!1sde!2sde"
          className="rich"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <div className="column">
          <div className="text">
            Gefeiert wird bei{' '}
            <a href="https://maps.app.goo.gl/RovMQs2sy2eQzDwW7">
              Hotel Hartl&apos;s Lindenmühle
            </a>
            .
            <br />
            Parkplätze sind hinter dem Haus genügend vorhanden.
            <br />
            Dafür müsst ihr lediglich einmal links am Hotel durch die Passage
            fahren.
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.919530234966!2d11.6740536768106!3d50.05033871609152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1a0d86bc7a605%3A0xceb4d087e2241351!2sHotel%20Hartl&#39;s%20Lindenm%C3%BChle!5e0!3m2!1sde!2sde!4v1708270383357!5m2!1sde!2sde"
            className="rich"
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="column">
          <div className="text">
            Die blaue Zone wird nur für uns reserviert!
            <br />
            Ihr dürft euch auch gegenseitig zuparken ;)
          </div>
          <img style={{ width: '30%' }} src="/img/anfahrt.png" alt="" />
        </div> */}
      </div>
    </div>
  );
};
