import { useEffect, useState } from 'react';

import API from './API';

function useAPI<T>(apiUri: string) {
  const [item, setItem] = useState<T>();
  const localStorageKey = apiUri.replaceAll('/[^a-zA-Z0-9]/', '');

  console.log(`[useAPI] using endpoint ${apiUri}`);

  useEffect(() => {
    const temp = localStorage.getItem(localStorageKey);
    if (temp !== '' && temp !== null) {
      console.log(`[useAPI][${apiUri}] using cache`);
      if (temp && temp !== 'undefined') setItem(JSON.parse(temp) as T);
    }

    API.get<T>(apiUri)
      .then((e) => {
        localStorage.setItem(localStorageKey, JSON.stringify(e.data));
        console.log(`[useAPI][${apiUri}] updating cache`);
        setItem(e.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return item;
}

export default useAPI;
