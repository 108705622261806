import './Kontakt.css';

import React from 'react';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../Components/Header';
import { getLanguage } from '../languages/getLanguage';

const Trauzeuge = (data: {
  image: string;
  name: string;
  side: string;
  additional: { name: string; image: string }[];
  text: string;
}) => {
  return (
    <div className="trauzeuge">
      <h5>{data.side}</h5>
      <div
        className="image"
        style={{ backgroundImage: `url(${data.image})` }}
      ></div>
      <h6>{data.name}</h6>
      <hr />
      <div className="others">
        <div>
          {data.additional
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .map((e) => (
              <div key={e.name}>
                <img src={e.image} />
                <span>{e.name}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const Kontakt = () => {
  const { NavigationStatic, ContactStatic } = getLanguage();

  return (
    <div className="inner">
      <Header image="/img/us/kontakt.jpg" title={NavigationStatic.contact} />
      <div className="container" style={{ paddingBottom: 80, paddingTop: 30 }}>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-6 order-md-1 order-sm-1 order-1">
            <Trauzeuge
              name="Franziska"
              additional={[
                { name: 'Susi', image: '/img/susi.jpg' },
                { name: 'Svenja', image: '/img/svenja.jpg' },
              ]}
              side="Bride Squad"
              text="Franzi ist Kerstins langwierige Freundin aus der Kindheit. Schon in jungen Jahren waren beide stets für einander da. Alleine sind sie toll, zusammen unschlagbar!"
              image="/img/franzi.jpg"
            />
          </div>
          <div className="col-md-4 col-sm-12 col-12 order-md-1 order-sm-2 order-2">
            <div className="trauzeugenInformation">
              <h2>{ContactStatic.headline}</h2>
              <p>{ContactStatic.text1}</p>
              <p>{ContactStatic.text2}</p>
              <p>{ContactStatic.text3}</p>
              <p>{ContactStatic.text4}</p>
              <p>
                {ContactStatic.text5}{' '}
                <FontAwesomeIcon color="rgb(62, 3, 60)" icon={faHeart} />
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-6 order-md-1 order-sm-1 order-1">
            <Trauzeuge
              name="Christoph Kreutzer"
              additional={[
                { name: 'Phil', image: '/img/phil.jpg' },
                { name: 'Patrick', image: '/img/patrick.jpg' },
              ]}
              side="Groom Squad"
              text="Christoph und Max lernten sich durch das Studium kennen woraus sich schnell eine tiefe Freundschaft über das Studium und die Arbeit hinaus bildete."
              image="/img/chris.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
