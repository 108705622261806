import './Photos.css';

import React from 'react';

import { Header } from '../Components/Header';
import { getLanguage } from '../languages/getLanguage';

export const PhotosPage = () => {
  const { NavigationStatic } = getLanguage();

  return (
    <div className="inner">
      <Header image="/img/us/DSC00651.jpg" title={NavigationStatic.photos} />
      <div className="photos">
        <div className="upload">
          <h5>Die Fotos müssen erst noch geknipst werden!</h5>
        </div>
      </div>
    </div>
  );
};
