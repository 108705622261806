import './RSVP.css';

import React, { useEffect, useState } from 'react';
import reactStringReplace from 'react-string-replace';

import { faCheck, faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../Components/Header';
import { Login } from '../Components/Login';
import API from '../Helper/API';
import { IApiRsvp } from '../interfaces/IApiRsvp';
import { getLanguage } from '../languages/getLanguage';
import { RSVPAdmin } from './RSVPAdmin';

export const RSVP = () => {
  const [code, setCode] = useState(localStorage.getItem('code') ?? '');
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const whoRef = React.useRef<HTMLInputElement>(null);
  const [rsvp, setRsvp] = useState<IApiRsvp>({} as IApiRsvp);
  const { NavigationStatic, RSVPStatic } = getLanguage();

  useEffect(() => {
    if (code === '') return;
    API.get(`/rsvp/${code}`)
      .then((e) => {
        console.log('rsvp', e);
        setRsvp(e.data);
        localStorage.setItem('rsvp', JSON.stringify(e.data));
      })
      .catch((e) => {
        console.log('rsvp::error', e);
        setError(true);
      });
  }, [code]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('rsvp') ?? '{}') as IApiRsvp;
    if (data.code !== '') setRsvp(data);
    const cd = localStorage.getItem('code');
    if (cd !== '' && cd) {
      setCode(cd);
    }
  }, []);

  const saveForm = () => {
    setSaved(true);
    setTimeout(() => setSaved(false), 3 * 1000);
    const r: IApiRsvp = { ...rsvp, people: whoRef.current?.value ?? '' };
    API.put(`/rsvp/${code}`, r).then(() => {
      localStorage.setItem('rsvp', JSON.stringify(r));
      setRsvp(r);
      setTimeout(() => setSaved(false), 1 * 1000);
    });
  };

  if (code === '')
    return (
      <div className="inner">
        <Header
          images={[
            '/img/us/RSVP_1.jpg',
            '/img/us/RSVP_2.jpg',
            '/img/us/RSVP_3.jpg',
          ]}
          title="RSVP"
        />
        <div className="home" style={{ textAlign: 'left' }}>
          <Login title={RSVPStatic.login} />
        </div>
      </div>
    );

  if (error) {
    return (
      <div className="inner">
        <Header
          images={[
            '/img/us/RSVP_1.jpg',
            '/img/us/RSVP_2.jpg',
            '/img/us/RSVP_3.jpg',
          ]}
          title={NavigationStatic.rsvp}
        />
        <div className="home" style={{ textAlign: 'left' }}>
          <div className="alert alert-danger">{RSVPStatic.wrong_code}</div>
          <Login title={RSVPStatic.wrong_code_login} />
        </div>
      </div>
    );
  }

  return (
    <div className="inner">
      <Header
        images={[
          '/img/us/RSVP_1.jpg',
          '/img/us/RSVP_2.jpg',
          '/img/us/RSVP_3.jpg',
        ]}
        title={NavigationStatic.rsvp}
      />
      <div className="home rsvp">
        <form action="#">
          <div className="rsvp-container">
            <div className="row">
              <div className="col">
                <label className="form-label">
                  {RSVPStatic.hello} {rsvp.name} 🤗
                </label>
              </div>
              <div className="col">
                <label className="form-label">
                  {RSVPStatic.your_code}: {rsvp.code}
                </label>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">{RSVPStatic.comming}</label>
              <br />
              <div className="btn-group">
                <button
                  type="button"
                  className={`btn btn-${rsvp.comming === 1 ? '' : 'outline-'}success`}
                  defaultChecked={rsvp.comming === 1}
                  onClick={() => setRsvp({ ...rsvp, comming: 1 })}
                >
                  {rsvp.comming === 1 ? <FontAwesomeIcon icon={faCheck} /> : ''}{' '}
                  {RSVPStatic.yes}
                </button>
                <button
                  type="button"
                  className={`btn btn-${rsvp.comming === 0 ? '' : 'outline-'}danger`}
                  defaultChecked={rsvp.comming === 0}
                  onClick={() => setRsvp({ ...rsvp, comming: 0 })}
                >
                  {rsvp.comming === 0 ? <FontAwesomeIcon icon={faTimes} /> : ''}{' '}
                  {RSVPStatic.no}
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">{RSVPStatic.who_comming}</label>
              <input
                className="form-control"
                defaultValue={rsvp.people}
                ref={whoRef}
              />
              <div className="form-text">
                {reactStringReplace(
                  RSVPStatic.who_comming_info,
                  /_(.*)_/,
                  (match) => (
                    <kbd key={match}>{match}</kbd>
                  ),
                )}
              </div>
            </div>
            <div>
              <label className="form-label">{RSVPStatic.hotel}</label>
              <br />
              <div className="btn-group">
                <button
                  type="button"
                  className={`btn btn-${rsvp.hotel === -1 ? '' : 'outline-'}default`}
                  defaultChecked={rsvp.hotel === -1}
                  onClick={() => setRsvp({ ...rsvp, hotel: -1 })}
                >
                  {rsvp.hotel === -1 ? <FontAwesomeIcon icon={faClock} /> : ''}{' '}
                  {RSVPStatic.unknown}
                </button>
                <button
                  type="button"
                  className={`btn btn-${rsvp.hotel === 1 ? '' : 'outline-'}success`}
                  defaultChecked={rsvp.hotel === 1}
                  onClick={() => setRsvp({ ...rsvp, hotel: 1 })}
                >
                  {rsvp.hotel === 1 ? <FontAwesomeIcon icon={faCheck} /> : ''}{' '}
                  {RSVPStatic.booked}
                </button>
                <button
                  type="button"
                  className={`btn btn-${rsvp.hotel === 0 ? '' : 'outline-'}danger`}
                  defaultChecked={rsvp.hotel === 0}
                  onClick={() => setRsvp({ ...rsvp, hotel: 0 })}
                >
                  {rsvp.hotel === 0 ? <FontAwesomeIcon icon={faTimes} /> : ''}{' '}
                  {RSVPStatic.no}
                </button>
              </div>
              <div className="form-text">{RSVPStatic.hotel_info}</div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className={`btn btn-${saved ? 'success' : 'primary'}`}
              onClick={() => saveForm()}
            >
              {saved ? (
                <>
                  <FontAwesomeIcon icon={faCheck} /> {RSVPStatic.saved}
                </>
              ) : (
                RSVPStatic.save
              )}
            </button>
            <div id="emailHelp" className="form-text">
              {RSVPStatic.changeable}
            </div>
            <hr />
            {RSVPStatic.logout_prefix}
            <button
              type="button"
              className={`btn btn-link btn-sm`}
              onClick={() => {
                localStorage.removeItem('code');
                localStorage.removeItem('rsvp');
                window.location.reload();
              }}
            >
              {RSVPStatic.logout}
            </button>
          </div>
        </form>
        {rsvp.rights > 0 && <RSVPAdmin rsvp={rsvp} />}
      </div>
    </div>
  );
};
