import './Header.css';

import React, { useEffect, useState } from 'react';

import { Navigation } from './Navigation';

export const Header = (props: {
  title?: string;
  image?: string;
  images?: string[];
}) => {
  const [image, setImage] = useState({
    num: 0,
    images: [props.image || (props.images ?? ['']).at(0)],
  });

  useEffect(() => {
    let to: any = null;
    if (props.image) {
      setImage({ num: 0, images: [props.image] });
    } else if (props.images) {
      setImage({ num: 0, images: props.images });
      to = setInterval(() => {
        setImage((e) => ({
          num: e.num + 1 === e.images.length ? 0 : e.num + 1,
          images: e.images,
        }));
      }, 1000);
    }
    return () => {
      if (to !== null) clearTimeout(to);
    };
  }, []);

  return (
    <div id="header">
      <Navigation />
      <div id="background">
        {image.images.map((e, idx) => {
          const active = image.num === idx;
          return (
            <div
              className={`header-image ${active ? 'show' : 'hidden'}`}
              key={idx}
              style={{ backgroundImage: `url(${e}` }}
            ></div>
          );
        })}
        <div className="background dimmed">
          <h1>{props.title}</h1>
        </div>
      </div>
    </div>
  );
};
