import './Menu.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Header } from '../Components/Header';
import API from '../Helper/API';
import useAPI from '../Helper/useAPI';
import { getLanguage } from '../languages/getLanguage';

interface IFoodplan {
  id: number;
  name: string;
  category: string;
  main: string;
  menu: string;
  info: string;
  created_at: string;
  updated_at: string;
}

export function Menu() {
  // const foodplan = useAPI<IFoodplan[]>('/foodplan');

  const { NavigationStatic, MenuStatic } = getLanguage();

  return (
    <div className="inner">
      <Header image="/img/us/food.jpg" title={NavigationStatic.menu} />
      <div className="menu photos">
        <div className="upload">
          <h5>{MenuStatic.placeholder}</h5>
        </div>
        {/* <div className="flex">
          {foodplan?.map((e) => (
            <div key={e.menu}>
              <h2>{e.menu}</h2>
              <p>{e.name}</p>
              <ul>
                {e.main.split(',').map((a) => (
                  <li key={a}>{a}</li>
                ))}
              </ul>
              {e.info !== '' && <small>{e.info}</small>}
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}
