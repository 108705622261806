const HomeStatic = {
  background: '/img/us/startseite.jpg',
  texts: {
    headline: 'We are getting married',
    hashtag: '#TeamBernreuther',
    info: "until ~Bernreuther~'fication!",
    days: 'Party hard in -DAYS-!',
    days_text: 'Days left',
    save_the_date: 'Save The Date!',
  },
  names: {
    entries: ['Max & Maria', 'ReDi & Maria', 'ReDi & Kerstin', 'Max & Kerstin'],
    timeout: 5,
  },
  date: {
    day: 2,
    month: 5,
    year: 2025,
  },
};

const NavigationStatic = {
  home: 'Home',
  anfahrt: 'Location',
  menu: 'Meal',
  schedule: 'Schedule',
  contact: 'Contact',
  photos: 'Photos',
  rsvp: 'RSVP',
};

const AnfahrtStatic = {
  headline: 'The location is called',
  parking1: 'Parking is reserved behind the location.',
  parking2: 'Just drive behind the hotel on its left side',
  parking3: 'The blue zone is reserved for our parking!',
  parking4: 'You may lock others in - We are a closed group ;)',
};

const MenuStatic = {
  placeholder: 'The meals have to be cooked first :)',
};

const ScheduleStatic = {
  info: 'This page is subject to change :)',
};

const ContactStatic = {
  headline: 'The Squad',
  text1: 'Our Squad is responsible for the communication between us and you.',
  text2: 'Please feel free to send game requests and ideas.',
  text3:
    'Franzi and Chris and the rest of the squad will assist you on any questions and issues.',
  text4: 'Besides that, they assist us to make our day the perfect one.',
  text5: 'We can not describe how thankful we are to have all of you',
};
const RSVPStatic = {
  login:
    'To enter your data here you will require the code from your invitation!',
  wrong_code: 'This Code does not exist, please try again.',
  wrong_code_login: 'Please enter the correct code.',
  hello: 'Heyooo',
  your_code: 'Your code',
  comming: 'We/I will come',
  yes: 'yes',
  no: 'no',
  booked: 'room is booked',
  unknown: 'unclear',
  who_comming: 'Who is coming?',
  who_comming_info: 'To be able to calculate. e.g. Kerstin, Max, ...',
  hotel: 'We will sleep in the hotel',
  hotel_info:
    'The room has to be booked individually. Contact us if you need help.',
  saved: 'Saved',
  save: 'Save',
  changeable: 'You can change your data anytime.',
  logout_prefix: 'To register another code press here',
  logout: 'Log out',
};

export default {
  HomeStatic,
  NavigationStatic,
  AnfahrtStatic,
  MenuStatic,
  ScheduleStatic,
  ContactStatic,
  RSVPStatic,
};
