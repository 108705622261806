import React, { useEffect, useState } from 'react';

import {
  faCheck,
  faClock,
  faTimes,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import API from '../Helper/API';
import { IApiRsvp } from '../interfaces/IApiRsvp';

interface IRSVPAdmin {
  rsvp: IApiRsvp;
}
export function RSVPAdmin(props: IRSVPAdmin) {
  const [list, setList] = useState<IApiRsvp[]>([]);

  function loadRsvp() {
    API.get<IApiRsvp[]>('/rsvp/admin/list/' + props.rsvp.code).then((e) => {
      setList(e.data);
    });
  }

  useEffect(() => {
    loadRsvp();
  }, []);

  function updateGuest(item: IApiRsvp) {
    API.put(`/rsvp/${item.code}`, item).then((e) => {
      loadRsvp();
    });
  }

  return (
    <>
      <hr />
      <div className="rsvp-container">
        <h2>RSVP Administration</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Anwesend</th>
              <th>Zimmer gebucht</th>
              <th>Personen</th>
              <th>Letztes Update</th>
            </tr>
          </thead>
          <tbody>
            {list.map((e) => {
              let cls = '';
              //   if (cls === '' && e.comming === 1) cls = 'table-success';
              if (cls === '' && e.comming === 0) cls = 'table-secondary';
              if (cls === '' && e.hotel == -1) cls = 'table-warning';
              return (
                <tr key={e.id} className={cls}>
                  <td>{e.code}</td>
                  <td>
                    {e.rights > 0 && <FontAwesomeIcon icon={faUserSecret} />}{' '}
                    {e.name}
                  </td>
                  <td>
                    <button
                      onClick={() => updateGuest({ ...e, comming: 1 })}
                      className={`btn btn-default`}
                    >
                      <FontAwesomeIcon
                        color={e.comming == 1 ? '#000' : 'rgba(0,0,0,.2)'}
                        icon={faCheck}
                      />
                    </button>
                    <button
                      onClick={() => updateGuest({ ...e, comming: 0 })}
                      className={`btn btn-default btn-sm`}
                    >
                      <FontAwesomeIcon
                        color={e.comming == 0 ? '#000' : 'rgba(0,0,0,.2)'}
                        icon={faTimes}
                      />
                    </button>
                  </td>
                  <td>
                    <button className={`btn btn-default`}>
                      <FontAwesomeIcon
                        onClick={() => updateGuest({ ...e, hotel: -1 })}
                        color={e.hotel == -1 ? '#000' : 'rgba(0,0,0,.2)'}
                        icon={faClock}
                      />
                    </button>
                    <button className={`btn btn-default`}>
                      <FontAwesomeIcon
                        onClick={() => updateGuest({ ...e, hotel: 1 })}
                        color={e.hotel == 1 ? '#000' : 'rgba(0,0,0,.2)'}
                        icon={faCheck}
                      />
                    </button>
                    <button className={`btn btn-default btn-sm`}>
                      <FontAwesomeIcon
                        onClick={() => updateGuest({ ...e, hotel: 0 })}
                        color={e.hotel == 0 ? '#000' : 'rgba(0,0,0,.2)'}
                        icon={faTimes}
                      />
                    </button>
                  </td>
                  <td>{e.people}</td>
                  <td>{new Date(e.updated_at ?? '').toLocaleDateString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
