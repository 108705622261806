import './Schedule.css';

import React from 'react';

import { Header } from '../Components/Header';
import useAPI from '../Helper/useAPI';
import { getLanguage } from '../languages/getLanguage';

interface ISchedule {
  id: number;
  time: string;
  text: string;
  created_at: string;
  updated_at: string;
}

export const Schedule = () => {
  const timeline = useAPI<ISchedule[]>('schedule');
  const d = new Date();
  const theDay =
    d.getDay() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear() ===
    '2.5.2025';
  const { NavigationStatic, ScheduleStatic } = getLanguage();

  return (
    <div className="inner">
      <Header
        image="/img/us/tagesablauf.jpg"
        title={NavigationStatic.schedule}
      />
      <div className="timeline">
        <ul className="tl">
          {timeline
            ?.map((e, idx) => {
              if (!theDay) return { ...e, active: false, previous: false };
              const t = (n: string) => parseInt(`${n}`.replace(':', ''), 10);
              const curr = t(`${d.getHours()}:${d.getMinutes()}`);
              const to_time = timeline.at(idx + 1) ?? { time: '00:00' };

              const from = t(e.time) < curr;
              const to = t(to_time.time) > curr;

              return {
                ...e,
                previous: idx < timeline.length && from && t(e.time) > 1000,
                active: idx < timeline.length && from && to,
              };
            })
            .map((e) => (
              <li
                className={e.active ? 'active' : e.previous ? 'previous' : ''}
                key={e.time}
              >
                <small>{e.time}</small> <span>{e.text}</span>
              </li>
            ))}
        </ul>
        <small>{ScheduleStatic.info}</small>
      </div>
    </div>
  );
};
