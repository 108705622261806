import LangDE from './DE';
import LangEN from './EN';

export function getLanguage() {
  let lng = LangEN;
  let lang = 'en';
  try {
    lang = navigator.language.split('-')[0] ?? 'en';

    if (lang === 'de') {
      lng = LangDE;
    }
  } catch (e) {
    console.error('[LANG] Unknown language', navigator.language);
  }

  return lng;
}
