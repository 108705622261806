import React, { useState } from 'react'

import API from '../Helper/API'

export function Login(props: { title?: string }) {
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState(0);
  const codeRef = React.useRef<HTMLInputElement>(null);
  const btnRef = React.useRef<HTMLInputElement>(null);

  function onsubmit(e: any) {
    e.preventDefault();
    setDisabled(true);

    API.get<{ message: string }>('/rsvp/' + codeRef.current?.value)
      .then((e) => {
        setStatus(1);
        localStorage.setItem('code', codeRef.current?.value ?? '');
        setTimeout(() => window.location.reload(), 3 * 1000);
      })
      .catch((e) => {
        localStorage.removeItem('code');
        setStatus(2);
      })
      .finally(() => {
        setDisabled(false);
      });
  }

  return (
    <div className="upload">
      <h5>
        {props.title ??
          'Um Bilder hoch zu laden, musst du dich mit deinem Gäste Code anmelden!'}
      </h5>
      <span>
        {
          [
            '',
            'Der eingegebene Code ist korrekt! Du wirst in kurzer Zeit weiter geleitet!',
            'Der eingegebene code ist nicht korrekt!',
          ][status]
        }
      </span>
      <form onSubmit={onsubmit} method="POST" action="#">
        <div className="d-grid gap-2">
          <input
            ref={codeRef}
            className={`form-control ${['', 'is-valid', 'is-invalid'][status]}`}
            type="text"
            placeholder="Code"
            disabled={disabled}
          />
          <input
            disabled={disabled}
            className={`btn btn-${['primary', 'success', 'danger'][status]}`}
            type="submit"
            value={`${['anmelden', 'angemeldet', 'anmelden'][status]}`}
          />
        </div>
      </form>
    </div>
  );
}
