import './Navigation.css';

import { Container, Nav, Navbar } from 'react-bootstrap';
import React, { Link, useLocation } from 'react-router-dom';

import { getLanguage } from '../languages/getLanguage';

export function Navigation() {
  const nav = useLocation();
  const Lang = getLanguage().NavigationStatic;

  const items = [
    { to: '/', name: Lang.home },
    { to: '/anfahrt', name: Lang.anfahrt },
    { to: '/menu', name: Lang.menu },
    { to: '/schedule', name: Lang.schedule },
    { to: '/contact', name: Lang.contact },
    { to: '/photos', name: Lang.photos },
    { to: '/rsvp', name: Lang.rsvp },
  ];

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">Kerstin & Max</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {items.map((item) => (
              <Link
                key={item.to}
                className={item.to == nav.pathname ? 'active' : ''}
                to={item.to}
              >
                {item.name}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
