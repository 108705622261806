import { cloneDeep, merge } from 'lodash';

import EN from './EN';

const HomeStatic = merge(cloneDeep(EN.HomeStatic), {
  texts: {
    headline: 'Wir trauen uns!',
    hashtag: '#TeamBernreuther',
    info: "bis zur ~Bernreuther~'fizierung!",
    days: 'Noch -DAYS-!',
    days_text: 'Tage noch',
  },
  names: {
    entries: ['Max & Maria', 'ReDi & Maria', 'ReDi & Kerstin', 'Max & Kerstin'],
  },
});

const NavigationStatic = merge(cloneDeep(EN.NavigationStatic), {
  home: 'Startseite',
  anfahrt: 'Anfahrt',
  menu: 'Speiseplan',
  schedule: 'Tagesablauf',
  contact: 'Kontakt',
  photos: 'Fotos',
  rsvp: 'Rückmeldung',
});
const AnfahrtStatic = merge(cloneDeep(EN.AnfahrtStatic), {
  headline: 'Gefeiert wird bei',
  parking1: 'Parkplätze sind hinter dem Haus genügend vorhanden.',
  parking2:
    'Dafür müsst ihr lediglich einmal links am Hotel durch die Passage fahren.',
  parking3: 'Die blaue Zone wird nur für uns reserviert!',
  parking4: 'Ihr dürft euch auch gegenseitig zuparken ;)',
});
const MenuStatic = merge(cloneDeep(EN.MenuStatic), {
  placeholder: 'Die Gerichte werden noch zubereitet :)',
});
const ScheduleStatic = merge(cloneDeep(EN.ScheduleStatic), {
  info: 'Diese Seite wird nach und nach gefüllt :)',
});
const ContactStatic = merge(cloneDeep(EN.ContactStatic), {
  headline: 'Trauzeugen',
  text1:
    'Unsere Trauzeugen übernehmen die Kommunikation zwischen euch und uns.',
  text2: 'Ihr könnt gerne (Spiel)-Wünsche und anregungen teilen.',
  text3:
    'Bei Rückfragen stehen euch Franzi und Chris aber auch alle anderen Trauzeugen sehr gerne zur verfügung.',
  text4:
    'Nebenbei stehen sie uns mit Rat und Tat zur Seite, damit unser großer Tag perfekt wird.',
  text5: 'Vielen vielen Dank euch! Das wissen wir sehr zu schätzen',
});
const RSVPStatic = merge(cloneDeep(EN.RSVPStatic), {
  login:
    'Um deine Daten einzutragen, benötigst du deinen Code aus der einladung!',
  wrong_code: 'Du hast keinen oder einen falschen RSVP Code eingegeben.',
  wrong_code_login: 'Bitte gebe einen korrekten Code ein.',
  hello: 'Huhu',
  your_code: 'Dein Code',
  comming: 'Wir/Ich komme',
  yes: 'ja',
  no: 'nein',
  booked: 'Zimmer gebucht',
  unknown: 'unklar',
  who_comming: 'Wer kommt mit?',
  who_comming_info: 'Damit wir gut kalkulieren können. z.B. _Kerstin, Max_',
  hotel: 'Wir schlafen im Hotel',
  hotel_info:
    'Das Zimmer müsst ihr selber buchen! Ruft am besten dazu an. Zimmer sind reserviert.',
  saved: 'Gespeichert',
  save: 'Speichern',
  changeable: 'Ihr könnt eure Angaben jederzeit ändern.',
  logout_prefix: 'Um einen weiteren Code rückzumelden, hier',
  logout: 'Abmelden',
});

export default {
  HomeStatic,
  NavigationStatic,
  AnfahrtStatic,
  MenuStatic,
  ScheduleStatic,
  ContactStatic,
  RSVPStatic,
};
